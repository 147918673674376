import { CheckoutSdkFrameEventType, CheckoutSdkHostEventType } from '@mangopay/checkout-sdk-hosted-core';
import { useEffect, useState } from 'react';
import { CreatePaypalPayInResult } from '@mangopay/sdk-payment-methods';
import { useGlobalContext } from '../../globalContext';
import { CreatePaypalPaymentCompleteEvent, PaypalPostMessageEvent } from './types';
import { usePaypalPaymentComplete } from './usePaypalPaymentComplete';
import { useRedirectPopup } from '../../redirect/use-redirect-popup';
import { AuthorizeRedirectUrlParamKeys } from '../../common/types';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';

export const usePaypal = () => {
  const { setIsLoading, activePaymentMethod, profilingAttemptReference } = useGlobalContext();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { paymentComplete, isCreatePaypalPaymentError, isCreatePaypalPaymentSucceeded, handleCreatePaypalPayment } =
    usePaypalPaymentComplete();
  const { openPreloaderPopup, updatePopupUrl, closePopupWindow } = useRedirectPopup('paypal');
  const [paymentData, setPaymentData] = useState<CreatePaypalPayInResult | null>(null);

  const handlePaypalMessageEvent = (e: MessageEvent<PaypalPostMessageEvent>): void => {
    const { eventType, data } = e.data;
    if (eventType === CheckoutSdkHostEventType.HostAuthComplete) {
      const { Id, Status, Key } = data;
      if (Key === AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey && paymentData) {
        // validate Mangopay transaction
        paymentComplete({
          ...paymentData,
          Id,
          Status,
        });
        setPaymentData(null);
        setIsLoading(false);
        closePopupWindow();
        window.removeEventListener('message', handlePaypalMessageEvent);
      }
    }
  };

  const handlePaypalPayInCompleteEvent = (e: MessageEvent<CreatePaypalPaymentCompleteEvent>) => {
    const { data, eventType } = e.data;
    if (eventType === CheckoutSdkHostEventType.CreatePaypalPaymentComplete) {
      window.removeEventListener('message', handlePaypalPayInCompleteEvent);
      if (isCreatePaypalPaymentError(data)) {
        handleCreatePaypalPayment(data);
        setIsLoading(false);
        closePopupWindow();
        return;
      }
      if (isCreatePaypalPaymentSucceeded(data)) {
        setPaymentData(data);
        if (data.RedirectURL) {
          updatePopupUrl(data.RedirectURL);
        }
      }
    }
  };

  const handlePaypal = () => {
    openPreloaderPopup();
    dispatchMessageToApp(CheckoutSdkFrameEventType.CreatePaypalPayment, { profilingAttemptReference });
    window.addEventListener('message', handlePaypalPayInCompleteEvent);
  };

  useEffect(() => {
    if (activePaymentMethod === 'paypal' && paymentData) {
      window.addEventListener('message', handlePaypalMessageEvent);
    }
    return () => window.removeEventListener('message', handlePaypalMessageEvent);
  }, [activePaymentMethod, paymentData]);

  return {
    handlePaypal,
  };
};
