import { GooglePayCardParameters } from '@mangopay/sdk-payment-methods';
import { BasePaymentMethod } from '../../../common/types';

export const generateBaseCardPaymentMethod = (cardParameters?: GooglePayCardParameters): BasePaymentMethod => ({
  type: 'CARD',
  parameters: {
    allowedAuthMethods: cardParameters?.allowedAuthMethods,
    allowedCardNetworks: cardParameters?.allowedCardNetworks,
    allowPrepaidCards: cardParameters?.allowPrepaidCards,
    allowCreditCards: cardParameters?.allowCreditCards,
    billingAddressRequired: cardParameters?.billingAddressRequired,
    billingAddressParameters: cardParameters?.billingAddressParameters,
  },
});
