import { CheckoutSdkProxyMessagesOf } from './checkout-sdk-proxy-messages-of';
import { CheckoutSdkProxyMessage } from './checkout-sdk-proxy-message';

export abstract class BaseSdkEventsDispatcher<TSourceEventMap, TTargetEventMap> {
  protected target: MessageEventSource | undefined;

  protected targetOrigin: string | undefined;

  listen(host: Window, targetOrigin: string): () => void {
    this.targetOrigin = targetOrigin;

    const listener = (event: MessageEvent<CheckoutSdkProxyMessagesOf<TTargetEventMap>>) => this.onMessage(event);

    host.addEventListener('message', listener);

    return () => host.removeEventListener('message', listener);
  }

  protected abstract onIncomingMessage(message: CheckoutSdkProxyMessagesOf<TTargetEventMap>): void;

  protected dispatchMessage<T extends keyof TSourceEventMap>(eventType: T, data: TSourceEventMap[T]): void {
    if (!this.target) {
      throw new Error('Proxy target is required.');
    }

    const message: CheckoutSdkProxyMessage<T, TSourceEventMap[T]> = { eventType, data };
    try {
      this.target.postMessage(message, { targetOrigin: this.targetOrigin });
    } catch (e) {
      console.error(
        `Something went wrong. Please check the provided options. This error most likely happens when you try to pass a function that is not allowed.`
      );
      throw e;
    }
  }

  private onMessage(event: MessageEvent<CheckoutSdkProxyMessagesOf<TTargetEventMap>>): void {
    if (event.origin !== this.targetOrigin) {
      return;
    }

    if (!this.target) {
      this.target = event.source ?? undefined;
    }

    this.onIncomingMessage(event.data);
  }
}
