import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import {
  CheckoutContainer,
  CheckoutForm,
  CheckoutFormChangeType,
  CheckoutFormForwardedRef,
  FailedState,
  PAY_BUTTON_MAP,
  SuccessState,
  FullScreenLoader,
} from '@mangopay/checkout-sdk-react-elements';
import { AuthorizePostMessagePayload, getCardBrand, trimSpaces } from '@mangopay/checkout-sdk-core';
import { CheckoutSdkType } from '@mangopay/checkout-sdk-elements-core';
import { CheckoutSdkFrameEventType } from '@mangopay/checkout-sdk-hosted-core';
import { useApplePayPaymentComplete } from '../payment-methods';
import { useGlobalContext } from '../globalContext';
import { useHandlePayment } from './useHandlePayment';
import { getQueryParams } from '../utils/get-query-params';
import { AuthorizeRedirectUrlParamKeys } from '../common/types';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';
import { useInitSdk } from './useInitSdk';
import { isSdkContext } from '../utils/execution-context';
import { useCardMetadata } from '../payment-methods/card/useCardMetadata';

export const CheckoutSdk: FunctionComponent = () => {
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  const [hostErrorMessage, setHostErrorMessage] = useState<string | undefined>();
  const { sdkType } = getQueryParams();
  const { handleInitialize } = useInitSdk();
  const {
    isLoading,
    options,
    setCheckoutState,
    showSuccessState,
    activePaymentMethod,
    setIsCardFormComplete,
    isCardFormValidationTriggered,
    setIsCardFormValidationTriggered,
    setIsLoading,
  } = useGlobalContext();
  const formRef = useRef<CheckoutFormForwardedRef>(null);
  const { handlePayment, handlePaymentMethodSelect } = useHandlePayment({ formRef });
  const { cardMetadata, fetchMetadata } = useCardMetadata();
  const { handleAuthorizeApplePayComplete } = useApplePayPaymentComplete();
  const { initSdkEventsDispatcher, dispatchMessageToApp, updateHandlers } = useSdkEventsDispatcher();
  const handleHostAuthComplete = async (payload: AuthorizePostMessagePayload): Promise<void> => {
    const { Id, Key } = payload;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (Id && Key === AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey && activePaymentMethod === 'card') {
      await new Promise((resolve) => resolve(true));
    }
  };

  const handleSetLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  const handleEnable = () => setIsEnabled(true);
  const handleDisable = () => setIsEnabled(false);

  const handleHostSetErrorMessage = (errorMessage?: string): void => {
    if (typeof errorMessage !== 'string') return;
    setHostErrorMessage(errorMessage);
  };

  if (isSdkContext) {
    // we dont need to dispatch sdk events for HPP app
    const handlers = {
      handleInitialize,
      handleEnable,
      handleDisable,
      handleHostAuthComplete,
      handleHostSetErrorMessage,
      handlePayment,
      handleAuthorizeApplePayComplete,
      handleSetLoading,
    };

    updateHandlers(handlers);

    useEffect(() => {
      const unsubscribe = initSdkEventsDispatcher(handlers);
      return unsubscribe;
    }, []);
  }

  const handleChange = useCallback(
    (event: CheckoutFormChangeType) => {
      const { value } = event;
      const cardBrand = getCardBrand(value?.card?.cardNumber || '');
      setCheckoutState(event);
      if (hostErrorMessage) {
        setHostErrorMessage(undefined);
      }
      if (value.card && value.card.cardNumber && trimSpaces(value.card.cardNumber).length >= 8) {
        fetchMetadata(value.card.cardNumber);
      }

      dispatchMessageToApp(CheckoutSdkFrameEventType.Change, {
        isComplete: event.isComplete,
        value: {
          cardBrand,
        },
        errors: event.errors,
      });
    },
    [setHostErrorMessage, fetchMetadata]
  );

  const PayButton = PAY_BUTTON_MAP[activePaymentMethod] || PAY_BUTTON_MAP.card;
  const isFormDisabled = !isEnabled || isLoading;
  // const canPayWithCard = (isEnabled && checkoutState?.isComplete) ?? false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const payButtonIsDisabled = (activePaymentMethod === 'card' && !canPayWithCard) || isLoading || !isEnabled;
  return (
    <>
      {options && (
        <CheckoutContainer
          theme={options.branding}
          language={options.locale}
          amount={options.amount.value}
          currency={options.amount.currency}
        >
          <CheckoutForm
            ref={formRef}
            cardMetadata={cardMetadata}
            disabled={isFormDisabled}
            paymentMethods={options.paymentMethods}
            onChange={handleChange}
            onPaymentMethodSelect={handlePaymentMethodSelect}
            loading={isLoading}
            activePaymentMethod={activePaymentMethod}
            setIsCardFormComplete={setIsCardFormComplete}
            isCardFormValidationTriggered={isCardFormValidationTriggered}
            setIsCardFormValidationTriggered={setIsCardFormValidationTriggered}
          />
          {sdkType === CheckoutSdkType.Checkout ? <PayButton handlePay={handlePayment} /> : null}
          <FullScreenLoader isVisible={isLoading} />

          <FailedState showFailedState={Boolean(hostErrorMessage)} label={hostErrorMessage} />
          <SuccessState showSuccessState={showSuccessState} />
        </CheckoutContainer>
      )}
    </>
  );
};
