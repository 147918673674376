import { CreatePaypalPayInResult } from '@mangopay/sdk-payment-methods';
import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { useGlobalContext } from '../../globalContext';
import { PaymentStatus } from '../../common';
import { usePaymentResultState } from '../usePaymentResultState';
import { useSentryDebugger } from '../../sentryLogger';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';

/* Hook to show success state or failed state after payment is complete */
export const usePaypalPaymentComplete = () => {
  const { profilingAttemptReference: ProfilingAttemptReference } = useGlobalContext();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { logEvent, addBreadcrumb } = useSentryDebugger();
  const { handleErrorState, handleSuccessState } = usePaymentResultState();

  const isCreatePaypalPaymentError = (result: CreatePaypalPayInResult): boolean =>
    !!(
      result.errors ||
      result.Status === PaymentStatus.Error ||
      result.Type?.includes('invalid') ||
      result.Type?.includes('error') ||
      result.Type?.includes('forbidden')
    );

  const isCreatePaypalPaymentSucceeded = (result: CreatePaypalPayInResult): boolean =>
    !!((result.Status === PaymentStatus.Succeeded || result.Status === PaymentStatus.Created) && result.RedirectURL);

  const isCreatePaypalPayInFailed = (result: CreatePaypalPayInResult) => result.Status === PaymentStatus.Failed;

  const handleCreatePaypalPayment = (result: CreatePaypalPayInResult) => {
    const succeeded = isCreatePaypalPaymentSucceeded(result);
    const isError = isCreatePaypalPaymentError(result);
    const errorMessage = (isError && result.Message) || result.ResultMessage;
    const declineCode = isError && result.ResultCode && `error.${result.ResultCode}`;
    const declineMessage = errorMessage || declineCode || 'error.unknown';
    if (succeeded) {
      handleSuccessState();
    }
    if (isError) {
      handleErrorState({
        declineMessage,
        status: result?.Status,
        declineCode: result?.ResultCode,
        paymentMethod: 'paypal',
      });
    }
  };

  const handleResult = (result: CreatePaypalPayInResult) => {
    const succeeded = result.Status === 'SUCCEEDED';
    const failed = result.Status === 'FAILED';
    const isError = result.Status === 'ERROR';
    const { Id } = result;
    let errorMessage;
    if (!Id) {
      errorMessage = 'Transaction ID is missing';
    }
    if (isError && !result.Status) {
      errorMessage = 'Transaction status is missing';
    }
    const declineMessage = errorMessage || 'error.unknown';

    if (succeeded) {
      handleSuccessState();
    }
    if (isError) {
      handleErrorState({ declineMessage, status: result.Status, paymentMethod: 'paypal' });
    }
    return { succeeded, failed, isError };
  };

  const paymentComplete = (result: CreatePaypalPayInResult): void => {
    const { succeeded, failed } = handleResult(result);
    if (succeeded || failed) {
      addBreadcrumb(succeeded ? DebuggerLogType.PAYMENT_COMPLETED : DebuggerLogType.PAYMENT_FAILED, {
        paymentMethod: 'paypal',
      });
      logEvent({ message: succeeded ? 'Payment completed' : 'Payment failed' });
      // notify main app - PaymentComplete event
      dispatchMessageToApp(CheckoutSdkFrameEventType.PaymentComplete, {
        ...result,
        ...(ProfilingAttemptReference && { ProfilingAttemptReference }),
      });
    }
  };

  return {
    handleCreatePaypalPayment,
    isCreatePaypalPaymentSucceeded,
    isCreatePaypalPaymentError,
    isCreatePaypalPayInFailed,
    paymentComplete,
  };
};
