import { MangopayPaymentMethodsSdk, LookupMetadataInput, LookupMetadataOutput } from '@mangopay/sdk-payment-methods';

export const lookupMetadata = async (clientId: string, data: LookupMetadataInput): Promise<LookupMetadataOutput> => {
  const authorize = MangopayPaymentMethodsSdk.initialize({ clientId });

  let response: LookupMetadataOutput;
  try {
    response = await authorize.lookupMetadata(process.env.REACT_APP_BIN_LOOKUP_URL as string, data);
  } catch (error) {
    return error as LookupMetadataOutput;
  }

  return response;
};
