import { useRef } from 'react';
import { CreateGooglePayPayInResult } from '@mangopay/sdk-payment-methods';
import {
  CheckoutSdkFrameEventType,
  CheckoutSdkHostEventType,
  DebuggerLogType,
} from '@mangopay/checkout-sdk-hosted-core';
import { AuthorizePostMessagePayload, TypedError } from '@mangopay/checkout-sdk-core';
import { usePaymentResultState } from './usePaymentResultState';
import { SentryTagName, useSentryDebugger } from '../sentryLogger';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';
import { useGlobalContext } from '../globalContext';
import { PaymentStatus } from '../common';
import { getIsSecureModeValid, getSecureModeRedirectURL, RedirectPostMessageEvent } from './card';
import { useRedirectPopup } from '../redirect/use-redirect-popup';
import { AuthorizeRedirectUrlParamKeys } from '../common/types';

const isValidMessage = (data: AuthorizePostMessagePayload, checkId?: string): boolean => {
  const { Id, Status, Key } = data;
  const keyIsValid = Key === AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey;
  const idMatches = checkId === Id;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const isValidStatus = Status === PaymentStatus.Succeeded || Status === PaymentStatus.Failed;
  return keyIsValid && idMatches && isValidStatus;
};

export const useHandlePayInComplete = () => {
  const { profilingAttemptReference: ProfilingAttemptReference } = useGlobalContext();
  const { handleErrorState } = usePaymentResultState();
  const { addBreadcrumb, logEvent, logError, setTag } = useSentryDebugger();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { openPopupModal, closePopupWindow } = useRedirectPopup('3ds');
  const paymentDataRef = useRef<CreateGooglePayPayInResult | null>(null);

  const isCreatePaypalPaymentError = (result: CreateGooglePayPayInResult): boolean =>
    !!(
      result.Errors ||
      result.Status === PaymentStatus.Error ||
      result.Type?.includes('invalid') ||
      result.Type?.includes('forbidden')
    );

  const getPayInStatus = (result: CreateGooglePayPayInResult) => {
    if (isCreatePaypalPaymentError(result)) {
      return PaymentStatus.Error;
    }
    return result.Status;
  };

  const handleError = (result: CreateGooglePayPayInResult) => {
    const errorMessage = result.Message || result.ResultMessage;
    const declineCode = result.ResultCode && `error.${result.ResultCode}`;
    const declineMessage = errorMessage || declineCode || 'error.unknown';
    handleErrorState({
      declineMessage,
      status: result.Status,
      declineCode: result.ResultCode,
      paymentMethod: 'google_pay',
      errors: result.Errors,
    });
  };

  const handleDispatchUnknownError = (result: CreateGooglePayPayInResult): void => {
    const error: TypedError = {
      Status: 'ERROR',
      ResultCode: '205001',
      ResultMessage: result.Message || 'Unknown error',
    };
    setTag(SentryTagName.RESULT_CODE, error.ResultCode as string);
    setTag(SentryTagName.STATUS, error.Status as string);
    addBreadcrumb(DebuggerLogType.PAYMENT_ERRORED, { paymentMethod: 'google_pay', ...result }, 'error');
    logError(new Error(error.ResultMessage));
    dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
  };

  const handlePaymentProcessed = (result: CreateGooglePayPayInResult) => {
    const succeeded = result.Status === PaymentStatus.Succeeded;
    addBreadcrumb(succeeded ? DebuggerLogType.PAYMENT_COMPLETED : DebuggerLogType.PAYMENT_FAILED, {
      paymentMethod: 'google_pay',
      ...result,
    });
    logEvent({ message: succeeded ? 'Payment completed' : 'Payment failed' });
    // notify main app - PaymentComplete event
    dispatchMessageToApp(CheckoutSdkFrameEventType.PaymentComplete, {
      ...result,
      ...(ProfilingAttemptReference && { ProfilingAttemptReference }),
    });
  };

  const handleThreeDSMessageEvent = (e: MessageEvent<RedirectPostMessageEvent>): void => {
    const { eventType, data } = e.data;
    if (eventType !== CheckoutSdkHostEventType.HostAuthComplete) {
      return;
    }
    const isPaymentSucceeded = (data.Status as PaymentStatus) === PaymentStatus.Succeeded;
    setTag(SentryTagName.STATUS, data.Status);
    if (isValidMessage(data, paymentDataRef.current?.Id)) {
      addBreadcrumb(
        isPaymentSucceeded
          ? DebuggerLogType['3DS_AUTHENTICATION_COMPLETED']
          : DebuggerLogType['3DS_AUTHENTICATION_FAILED'],
        {
          paymentMethod: 'card',
          ...data,
        },
        isPaymentSucceeded ? 'log' : 'error'
      );
      if (paymentDataRef.current) {
        handlePaymentProcessed({
          ...paymentDataRef.current,
          Status: data.Status,
          ResultMessage: isPaymentSucceeded ? paymentDataRef.current.ResultMessage : '3D Secure verification failed',
        });
      }
    }
    paymentDataRef.current = null;
    closePopupWindow();
    window.removeEventListener('message', handleThreeDSMessageEvent);
  };

  const handlePaymentCreated = (result: CreateGooglePayPayInResult) => {
    const isSecureModeValid = getIsSecureModeValid(result);
    if (!isSecureModeValid) {
      const error = {
        Status: 'ERROR',
        ResultMessage: 'Invalid onCreatePayment function result format',
      };
      addBreadcrumb(DebuggerLogType.PAYMENT_ERRORED, result, 'error');
      logError(new Error(error.ResultMessage));
      dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
      return;
    }
    const secureModeRedirectURL = getSecureModeRedirectURL(result);
    // if payment requires 3ds open popup
    if (secureModeRedirectURL) {
      openPopupModal(secureModeRedirectURL);
      paymentDataRef.current = result;
      addBreadcrumb(DebuggerLogType['3DS_AUTHENTICATION_REQUESTED'], result);
      window.addEventListener('message', handleThreeDSMessageEvent);
    }
  };

  const handleCreatePayInPayment = (result: CreateGooglePayPayInResult): void => {
    const status = getPayInStatus(result);
    setTag(SentryTagName.STATUS, status);
    setTag(SentryTagName.PAY_IN_ID, result.Id);
    setTag(SentryTagName.RESULT_CODE, result.ResultCode as string);
    switch (status) {
      case PaymentStatus.Error:
        handleError(result);
        break;
      case PaymentStatus.Succeeded:
      case PaymentStatus.Failed:
        handlePaymentProcessed(result);
        break;
      case PaymentStatus.Created:
        handlePaymentCreated(result);
        break;
      default:
        handleDispatchUnknownError(result);
    }
  };
  return {
    handleCreatePayInPayment,
  };
};
