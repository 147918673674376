import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { CheckoutSdkFrameOptions } from '@mangopay/checkout-sdk-elements-core';
import { PaymentMethodType } from '@mangopay/sdk-payment-methods';
import { CheckoutFormState } from './common/types';

export interface GlobalContextValues {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  options?: CheckoutSdkFrameOptions;
  setOptions: (options?: CheckoutSdkFrameOptions) => void;
  checkoutState?: CheckoutFormState;
  setCheckoutState: Dispatch<SetStateAction<CheckoutFormState | undefined>>;
  showSuccessState: boolean;
  setShowSuccessState: (showSuccessState: boolean) => void;
  activePaymentMethod: PaymentMethodType;
  setActivePaymentMethod: (event: PaymentMethodType) => void;
  isCardFormComplete: boolean;
  setIsCardFormComplete: (isComplete: boolean) => void;
  isCardFormValidationTriggered: boolean;
  setIsCardFormValidationTriggered: (isComplete: boolean) => void;
  profilingAttemptReference: string;
  handlePaymentFlow?: boolean;
}

const GlobalContext = createContext<GlobalContextValues>({} as GlobalContextValues);
const GlobalContextProvider = GlobalContext.Provider;
const useGlobalContext = (): GlobalContextValues => useContext(GlobalContext);

export { GlobalContextProvider, useGlobalContext };
