import React from 'react';
import { CheckoutFormForwardedRef } from '@mangopay/checkout-sdk-react-elements';
import { PaymentMethodType } from '@mangopay/sdk-payment-methods';
import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';
import { SentryTagName, useSentryDebugger } from '../sentryLogger';
import { useApplePay, useCardPayment, useGooglePayButton, usePaypal } from '../payment-methods';
import { useNethoneProfiler } from '../nethone';
import { useGlobalContext } from '../globalContext';
import { useSdkEventsDispatcher } from '../sdk-events-dispatcher';

export const useHandlePayment = ({ formRef }: { formRef: React.RefObject<CheckoutFormForwardedRef> }) => {
  const { activePaymentMethod, isLoading, setIsLoading, setActivePaymentMethod } = useGlobalContext();
  const { addBreadcrumb, logError, setTag } = useSentryDebugger();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();

  const { handlePaypal } = usePaypal();
  const { payWithCard } = useCardPayment();
  const { renderGooglePayButton } = useGooglePayButton();
  const { payWithApplePay } = useApplePay();
  const { checkIsCompleted } = useNethoneProfiler();

  const handlePaymentMethodSelect = (paymentMethod: PaymentMethodType) => {
    setActivePaymentMethod(paymentMethod);
    addBreadcrumb(DebuggerLogType.PAYMENT_METHOD_SELECTED, { paymentMethod });
    setTag(SentryTagName.PAYMENT_METHOD, paymentMethod);
    /** we want to display the google card branded button here */
    if (paymentMethod === 'google_pay') {
      setTag(SentryTagName.PAYMENT_METHOD, paymentMethod);
      renderGooglePayButton().catch(() => {});
    }

    dispatchMessageToApp(CheckoutSdkFrameEventType.PaymentMethodSelect, { paymentMethod });
  };

  const handlePayment = () => {
    try {
      if (isLoading) return;
      addBreadcrumb(DebuggerLogType.PAYMENT_STARTED, { paymentMethod: activePaymentMethod });
      setTag(SentryTagName.PAYMENT_METHOD, activePaymentMethod);

      /** Handles Card */
      if (activePaymentMethod === 'card') {
        setIsLoading(true);
        payWithCard()
          .then(() => formRef.current?.clearForm())
          .catch(() => {})
          .finally(() => setIsLoading(false));
        return;
      }

      // check if nethone profiling is completed before proceed with a payment
      checkIsCompleted().catch((e: Error) => {
        logError(new Error('Nethone Profiling failed'));
        console.error(e);
      });

      /** Handles Apple Pay */
      if (activePaymentMethod === 'apple_pay') {
        payWithApplePay();
        return;
      }

      /** Handles PayPal */
      if (activePaymentMethod === 'paypal') {
        handlePaypal();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  return {
    handlePayment,
    handlePaymentMethodSelect,
  };
};
