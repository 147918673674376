import { CheckoutSdkOptions, SdkOptions } from '@mangopay/checkout-sdk-elements-core';

const baseSdkOptions: Array<SdkOptions> = [SdkOptions.ClientId, SdkOptions.Amount, SdkOptions.ProfilingMerchantId];

export interface FindMissingOptionsResult {
  missingOptions: SdkOptions[];
  hasMissingOptions: boolean;
}

export const findMissingOptions = (options?: CheckoutSdkOptions): FindMissingOptionsResult => {
  const missingOptions = baseSdkOptions.filter((option: SdkOptions) => (options ? !options?.[option] : true));
  const hasMissingOptions = missingOptions.length > 0;

  return {
    missingOptions,
    hasMissingOptions,
  };
};
