export enum CheckoutSdkHostEventType {
  Initialize = 'initialize',
  Disable = 'disable',
  Enable = 'enable',
  ClickOutside = 'clickOutside',
  HostAuthComplete = 'hostAuthComplete',
  SetErrorMessage = 'setErrorMessage',
  CompletePayment = 'completePayment',
  PaymentComplete = 'paymentComplete',
  TokenizationComplete = 'tokenizationComplete',
  AuthorizeApplePayComplete = 'authorizeApplePayComplete',
  GetGooglePayToken = 'getGooglePayToken',
  SetLoading = 'setLoading',
  CreateCardRegistrationComplete = 'createCardRegistrationComplete',
  CreatePaypalPaymentComplete = 'createPaypalPaymentComplete',
  CreateCardPaymentComplete = 'createCardPaymentComplete',
  CreateGooglePayPaymentComplete = 'createGooglePayPaymentComplete',
  CreateApplePayPaymentComplete = 'createApplePayPaymentComplete',
  LogHostEvent = 'logHostEvent',
  LogHostError = 'logHostError',
}
