/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { CheckoutSdkEnvironment, GooglePayPaymentMethodOptions } from '@mangopay/checkout-sdk-elements-core';
import { TypedError } from '@mangopay/checkout-sdk-core';
import { CheckoutSdkFrameEventType, DebuggerLogType } from '@mangopay/checkout-sdk-hosted-core';

import { useEffect } from 'react';
import {
  baseRequest,
  generateBaseCardPaymentMethod,
  generateCardPaymentMethod,
  mergeGooglePayConfig,
  defaultGooglePayConfig,
  generatePaymentDataRequest,
  checkIsGooglePayLoaded,
  DefaultGooglePayButtonConfig,
  getGooglePayApm,
} from './utils';

import { useGooglePayHandlers } from './useGooglePayHandlers';
import { LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE } from './constants';
import { GoogleWindow, GoogleWindowResult, PaymentsClientResult } from './types';
import { useGlobalContext } from '../../globalContext';
import { useSentryDebugger } from '../../sentryLogger';
import { validateRequiredQueryParams } from '../../utils/get-query-params';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';

export type GooglePayEnvironment = CheckoutSdkEnvironment.PRODUCTION | 'TEST';

/* See react-element sdk */
const containerId = 'google_button_container';

export const useGooglePayButton = () => {
  const { options } = useGlobalContext();
  const { addBreadcrumb, logError } = useSentryDebugger();
  const { payWithGooglePay, handleDispatchUnknownError } = useGooglePayHandlers();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { sourceOrigin } = validateRequiredQueryParams();

  // find googlePay config

  const googlePayApm = getGooglePayApm(options?.paymentMethods);
  const googlePayOption = googlePayApm?.options as GooglePayPaymentMethodOptions;

  useEffect(() => {
    if (options && googlePayApm && !googlePayOption) {
      throw new Error('Options.paymentMethods with types google_pay should be configured.');
    }
  }, [options, googlePayOption, googlePayApm]);

  const googlePayEnvironment: GooglePayEnvironment =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    options?.environment === CheckoutSdkEnvironment.PRODUCTION ? CheckoutSdkEnvironment.PRODUCTION : 'TEST';

  // merge default config with user config
  const googlePayConfig = mergeGooglePayConfig(defaultGooglePayConfig, googlePayOption, googlePayEnvironment);
  // generate base card payment method
  const baseCardPaymentMethod = generateBaseCardPaymentMethod(googlePayConfig.cardParameters);

  // generate  card payment method with baseCardPaymentMethod
  const cardPaymentMethod = generateCardPaymentMethod(
    baseCardPaymentMethod,
    googlePayConfig?.gatewayMerchantId || googlePayConfig.merchantInfo?.merchantId,
    googlePayConfig?.gateway
  );
  // const cardPaymentMethod = generateCardPaymentMethod(baseCardPaymentMethod, googlePayConfig.merchantInfo?.merchantId);

  // generate payment data request
  const paymentDataRequest = generatePaymentDataRequest({
    cardPaymentMethod,
    transactionInfo: googlePayConfig.transactionInfo,
    merchantInfo: googlePayConfig.merchantInfo,
    paymentData: googlePayConfig.paymentData,
    sourceOrigin,
  });

  const renderGooglePayButton = async (): Promise<void> => {
    if (!checkIsGooglePayLoaded()) {
      handleDispatchUnknownError();
      return;
    }

    const buttonConfig = googlePayConfig.button as DefaultGooglePayButtonConfig;

    try {
      const google: GoogleWindowResult =
        (window as unknown as GoogleWindow)?.google || (global as unknown as GoogleWindow)?.google;

      // configure environment
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */
      /* @ts-ignore */
      const paymentsClient: PaymentsClientResult = new google.payments.api.PaymentsClient({
        environment: googlePayConfig.environment,
      });

      const isReadyToPayPayload = {
        ...baseRequest,
        allowedPaymentMethods: [baseCardPaymentMethod],
      };

      const isReadyResponse = await paymentsClient.isReadyToPay(isReadyToPayPayload);

      const isReadyToPay = isReadyResponse?.result;

      if (!isReadyToPay) return;

      const { transactionId } = googlePayConfig.transactionInfo;

      // if custom card button trigger google pop-up directly
      // if (options?.customButton) {
      //  await payWithGooglePay({ paymentsClient, paymentDataRequest, transactionId });
      //  return;
      // }

      // create button
      const button = paymentsClient.createButton({
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: (): Promise<void> => payWithGooglePay({ paymentsClient, paymentDataRequest, transactionId }),
        allowedPaymentMethods: [cardPaymentMethod],
        buttonSizeMode: buttonConfig.buttonSizeMode,
        buttonColor: buttonConfig.buttonColor,
        buttonType: buttonConfig.buttonType,
        buttonLocale: buttonConfig.buttonLocale,
      });

      const container = document.getElementById(containerId);

      if (buttonConfig.buttonColor === 'white' && container) {
        container.style.padding = '2px';
      }

      // add a Google Pay payment button to dom
      container?.appendChild(button);
    } catch {
      const error: TypedError = {
        Status: 'ERROR',
        ResultMessage: LOAD_GOOGLE_SCRIPT_ERROR_MESSAGE,
      };
      addBreadcrumb(DebuggerLogType.GOOGLE_PAY_SCRIPT_LOAD_FAILED, undefined, 'error');
      logError(new Error(error.ResultMessage));
      dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
    }
  };

  return { renderGooglePayButton };
};
