import { useEffect, useRef, useState } from 'react';
import { CreateCardDirectPayInResult, CreateCardRegistrationResult } from '@mangopay/sdk-payment-methods';
import { AuthorizePostMessagePayload, CardBrand, getCardBrand, TypedError } from '@mangopay/checkout-sdk-core';
import {
  CheckoutSdkFrameEventType,
  CheckoutSdkHostEventType,
  DebuggerLogType,
} from '@mangopay/checkout-sdk-hosted-core';
import { CardInfoObject } from '@mangopay/vault-sdk';
import { useGlobalContext } from '../../globalContext';
import { useTokenizePaymentMethod } from '../../api/vault';
import { CreateCardPaymentCompleteEvent, CreateCardRegistrationCompleteEvent, RedirectPostMessageEvent } from './types';
import { PaymentStatus } from '../../common';
import { useRedirectPopup } from '../../redirect/use-redirect-popup';
import { getIsSecureModeValid, getSecureModeRedirectURL } from './validateSecureMode';
import { SentryTagName, useSentryDebugger } from '../../sentryLogger';
import { useNethoneProfiler } from '../../nethone';
import { useCardPaymentComplete } from './useCardPaymentComplete';
import { AuthorizeRedirectUrlParamKeys } from '../../common/types';
import { useSdkEventsDispatcher } from '../../sdk-events-dispatcher';

interface PromiseResolver {
  resolve?: () => void;
  reject?: (reason?: unknown) => void;
}

export const useCardPayment = () => {
  const {
    options,
    checkoutState,
    setIsCardFormValidationTriggered,
    isCardFormComplete,
    activePaymentMethod,
    profilingAttemptReference: ProfilingAttemptReference,
  } = useGlobalContext();
  const { logError, logEvent, addBreadcrumb, setTag } = useSentryDebugger();
  const { dispatchMessageToApp } = useSdkEventsDispatcher();
  const { checkIsCompleted } = useNethoneProfiler();
  const { paymentComplete, handleResult, handleTokenizationResult } = useCardPaymentComplete();
  const { tokenizePaymentMethod } = useTokenizePaymentMethod();
  const { openPopupModal, closePopupWindow } = useRedirectPopup('3ds');
  const [paymentData, setPaymentData] = useState<CreateCardDirectPayInResult | null>(null);
  const cardInfoObject: CardInfoObject = {
    cardNumber: checkoutState?.value?.card?.cardNumber?.replace(/ /g, '') as string,
    cardExpirationDate: checkoutState?.value?.card?.cardExpiry?.replace('/', '') as string,
    cardCvx: checkoutState?.value?.card?.cardCvc as string,
    cardHolderName: checkoutState?.value?.card?.billingName as string,
  };
  const preferredCardNetwork = checkoutState?.value?.card?.preferredCardNetwork;
  const handleCardPayment = options?.handleCardPayment;
  const promiseResolvers = useRef<PromiseResolver>({});

  const handleCreateCardPaymentCompleteEvent = (e: MessageEvent<CreateCardPaymentCompleteEvent>): void => {
    const { data, eventType } = e.data;
    if (eventType === CheckoutSdkHostEventType.CreateCardPaymentComplete) {
      const isSecureModeValid = getIsSecureModeValid(data);
      setTag(SentryTagName.PAY_IN_ID, data.Id);
      if (!isSecureModeValid) {
        const error = {
          Status: 'ERROR',
          ResultMessage: 'Invalid onCreatePayment function result format',
        };
        addBreadcrumb(DebuggerLogType.PAYMENT_ERRORED, data, 'error');
        logError(new Error(error.ResultMessage));
        dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
        promiseResolvers.current.reject?.();
        return;
      }

      const secureModeRedirectURL = getSecureModeRedirectURL(data);
      // if payment requires 3ds open popup
      if (data.Status === PaymentStatus.Created && secureModeRedirectURL) {
        openPopupModal(secureModeRedirectURL);
        setPaymentData(data);
        addBreadcrumb(DebuggerLogType['3DS_AUTHENTICATION_REQUESTED'], data);
      } else {
        // otherwise flow is over
        const resolveFn =
          (data.Status as PaymentStatus) === PaymentStatus.Succeeded
            ? promiseResolvers.current.resolve
            : promiseResolvers.current.reject;
        resolveFn?.();
        paymentComplete(data);
      }
      window.removeEventListener('message', handleCreateCardPaymentCompleteEvent);
    }
  };

  const isValidMessage = (data: AuthorizePostMessagePayload, checkId?: string): boolean => {
    const { Id, Status, Key } = data;
    const keyIsValid = Key === AuthorizeRedirectUrlParamKeys.RedirectedFromAuthKey;
    const idMatches = checkId === Id;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    const isValidStatus = Status === PaymentStatus.Succeeded || Status === PaymentStatus.Failed;
    return keyIsValid && idMatches && isValidStatus;
  };

  const handleThreeDSMessageEvent = (e: MessageEvent<RedirectPostMessageEvent>): void => {
    const { eventType, data } = e.data;
    if (eventType === CheckoutSdkHostEventType.HostAuthComplete) {
      const isPaymentSucceeded = (data.Status as PaymentStatus) === PaymentStatus.Succeeded;
      if (isValidMessage(data, paymentData?.Id)) {
        addBreadcrumb(
          isPaymentSucceeded
            ? DebuggerLogType['3DS_AUTHENTICATION_COMPLETED']
            : DebuggerLogType['3DS_AUTHENTICATION_FAILED'],
          data,
          isPaymentSucceeded ? 'log' : 'error'
        );
        if (paymentData) {
          paymentComplete({
            ...paymentData,
            Status: data.Status,
            ResultMessage: isPaymentSucceeded ? paymentData.ResultMessage : '3D Secure verification failed',
          });
        }
      }
      if (isPaymentSucceeded) {
        promiseResolvers.current.resolve?.();
        setPaymentData(null);
      } else {
        promiseResolvers.current.reject?.();
      }
      closePopupWindow();
      window.removeEventListener('message', handleThreeDSMessageEvent);
    }
  };

  const handleTokenizePaymentMethod = async (data: CreateCardRegistrationResult) => {
    const preregistrationData = {
      id: data?.Id as string,
      cardRegistrationURL: data?.CardRegistrationURL as string,
      accessKeyRef: data?.AccessKey as string,
      data: data?.PreregistrationData as string,
    };
    const tokenizePaymentMethodResult = await tokenizePaymentMethod(preregistrationData, cardInfoObject);
    const { failed, isError } = handleTokenizationResult(tokenizePaymentMethodResult);
    if (isError || failed) {
      promiseResolvers.current.reject?.();
      return;
    }

    if (!handleCardPayment) {
      promiseResolvers.current.resolve?.();
      return;
    }
    const createCardPaymentData = {
      ...tokenizePaymentMethodResult,
      ...(ProfilingAttemptReference && { ProfilingAttemptReference }),
    };

    if (preferredCardNetwork) {
      createCardPaymentData.PreferredCardNetwork = preferredCardNetwork;
      setTag(SentryTagName.PREFERRED_CARD_NETWORK, preferredCardNetwork);
    }

    dispatchMessageToApp(CheckoutSdkFrameEventType.CreateCardPayment, createCardPaymentData);
    window.addEventListener('message', handleCreateCardPaymentCompleteEvent);
  };

  const handleCreateCardRegistrationEvent = async (
    e: MessageEvent<CreateCardRegistrationCompleteEvent>
  ): Promise<void> => {
    const { data, eventType } = e.data;
    if (eventType === CheckoutSdkHostEventType.CreateCardRegistrationComplete) {
      const { isError, failed } = handleResult(data);
      if (isError) {
        promiseResolvers.current.reject?.();
        return;
      }
      setTag(SentryTagName.CARD_REGISTRATION_ID, data.Id as string);
      if (failed) {
        addBreadcrumb(DebuggerLogType.CARD_REGISTRATION_FAILED, data, 'info');
        logEvent({ message: 'Create card registration failed' });
        promiseResolvers.current.reject?.();
        return;
      }
      addBreadcrumb(DebuggerLogType.CARD_REGISTRATION_COMPLETED, data);
      logEvent({ message: 'Create card registration completed' });
      window.removeEventListener('message', handleCreateCardRegistrationEvent);
      await handleTokenizePaymentMethod(data);
    }
  };

  const payWithCard = () =>
    new Promise<void>((resolve, reject) => {
      setIsCardFormValidationTriggered(!isCardFormComplete);

      if (!isCardFormComplete) {
        console.error('Card form is not valid');
        reject();
        return;
      }
      // for card payment method check if nethone profiling is completed only after form validated successfully
      checkIsCompleted().catch((e: Error) => {
        logError(new Error('Nethone Profiling failed'));
        console.error(e);
      });
      promiseResolvers.current.resolve = resolve;
      promiseResolvers.current.reject = reject;
      try {
        const cardBrand = getCardBrand(cardInfoObject.cardNumber);
        const cardType = [CardBrand.Visa, CardBrand.Master, CardBrand.Cb].includes(cardBrand)
          ? 'CB_VISA_MASTERCARD'
          : (cardBrand as string);
        setTag(SentryTagName.CARD_TYPE, cardType);
        dispatchMessageToApp(CheckoutSdkFrameEventType.CreateCardRegistration, { cardType });
        addBreadcrumb(DebuggerLogType.CARD_REGISTRATION_STARTED, { cardType });
        window.addEventListener('message', handleCreateCardRegistrationEvent);
      } catch (e) {
        const error = e as TypedError;
        dispatchMessageToApp(CheckoutSdkFrameEventType.Error, { error });
        logError(e as Error);
        reject();
      }
    });

  useEffect(() => {
    if (activePaymentMethod === 'card' && handleCardPayment && paymentData) {
      window.addEventListener('message', handleThreeDSMessageEvent);
    }
    return () => {
      window.removeEventListener('message', handleThreeDSMessageEvent);
    };
  }, [activePaymentMethod, handleCardPayment, paymentData]);

  return {
    payWithCard,
  };
};
